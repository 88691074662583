.ShortTermsBg:before {
  content: "";
  width: 100%;
  height: 266px;
  position: absolute;
  background: #ffffff;
  bottom: 0;
}
@screen sm {
  .ShortTermsBg::before {
    background: var(--bg-shortTerm-color);
  }
  .ShortTermsBg.HideTitle::before {
    background: #f0f6f8;
  }
}
